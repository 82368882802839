import { makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import CookieHelper from '../../../app/helpers/cookieHelper';
import { Card as CardData } from '../../../app/models/card';
import { useStore } from '../../../app/stores/store';
import CardDetails from './CardDetails';

const useStyle = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1, 1, 1, 2),
        margin: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));


const CardTitle = styled(Typography)`
        && {
        color: #172b4d;
        word-wrap: break-word;
        overflow: hidden auto;
        min-height:20px;
        max-height:256px;
        line-height: normal;
        }
`;

interface Props {
    card: CardData;
    index: number;
    cardGroupName: string;
}

const Card = ({ card, index, cardGroupName }: Props) => {
    const classes = useStyle();
    const { modalStore, cardAttachmentStore } = useStore();
    const history = useHistory()
    const search = useLocation().search;
    const taskId = new URLSearchParams(search).get("task");
 

    const handleOnClickCard = () => {
        if (card.id) {
            cardAttachmentStore.setShowAllAttachments(false);
            modalStore.openModal(<CardDetails id={card.id} cardGroupName={cardGroupName}/>)
            modalStore.setIsCard(true)
            let url = history.location.pathname;
            history.push(url + `?task=${card.id}`, { replace: true });
        } else {
            toast.error('Error in card loading.')
        }

    }

    if (taskId && card.id == taskId) {
        cardAttachmentStore.setShowAllAttachments(false);
        modalStore.openModal(<CardDetails id={card.id} cardGroupName={cardGroupName} />);
        modalStore.setIsCard(true)
    }


    return (
        <Draggable draggableId={card.id} index={index}>
            {(provided) => (
                <div ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}>
                    <Paper className={classes.card} onClick={handleOnClickCard}>
                        <CardTitle>
                            {card.title}
                        </CardTitle>
                    </Paper>
                </div>
            )}
        </Draggable>
    );
}

export default Card;
import saveAs from 'file-saver';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { Overtime } from '../models/overtime';
import { store } from './store';

export default class OvertimeStore {
    overtimes: Overtime[] = [];
    overtimesTotal: number = 0;
    isOvertimeListLoading: boolean = false;
    isInitialLoading: boolean = false;

    overtime: Overtime | undefined;
    isOvertimeDetailsLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterOvertimes = async (filters: FilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isOvertimeListLoading = true;

        try {
            const { total, data } = await agent.OvertimeRequests.filter({
                id: filters.id,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
                searchKeyword: filters.searchKeyword

            });
            runInAction(() => {
                this.overtimes = data;
                this.overtimesTotal = total;
                this.isOvertimeListLoading = false;
                this.isInitialLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isOvertimeListLoading = false;
            this.isInitialLoading = false;
        }
    }

    loadOvertime = async (id: string) => {
        this.isOvertimeDetailsLoading = true;
        try {
            const overtime = await agent.OvertimeRequests.get(id);
            runInAction(() => {
                this.overtime = overtime;
                this.isOvertimeDetailsLoading = false;

            })
        } catch (e) {
            console.log(e);
            this.isOvertimeDetailsLoading = false;
        }
    }

    approveOvertime = async (id: string, filters: FilterValues) => {
        this.isOvertimeListLoading = true;

        try {
            await agent.OvertimeRequests.approve(id, store.userStore.displayName);
            await this.filterOvertimes(filters);
            toast.success('Overtime approved.');
            this.isOvertimeDetailsLoading = false;

        } catch (e) {
            console.log(e);
            this.isOvertimeDetailsLoading = false;

        }
    }

    rejectOvertime = async (id: string, filters: FilterValues) => {
        this.isOvertimeDetailsLoading = true;

        try {
            await agent.OvertimeRequests.reject(id, store.userStore.displayName);
            await this.filterOvertimes(filters);
            toast.success('Overtime rejected.');
            this.isOvertimeDetailsLoading = false;

        } catch (e) {
            console.log(e);
            this.isOvertimeDetailsLoading = false;

        }
    }
}
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { history } from '../../index';
import agent from '../api/agents';
import { Board, BoardFormValues } from '../models/board';
import { Card } from '../models/card';
import { CardGroupOrder } from '../models/cardGroupOrder';
import { CardGroup } from '../models/cardGroup';
import { FilterValues } from '../models/common/filterValues';
import { store } from './store';
import UpdateOrderValues from '../../app/models/common/updateOrderValues';

export default class BoardStore {
    boards: Board[] = [];
    boardsTotal: number = 0;
    isBoardListLoading: boolean = false;
    isLoading: boolean = false;

    hubConnection: HubConnection | null = null;
    boardUrl = process.env.REACT_APP_BOARD_URL;

    board: Board | undefined;
    isBoardDetailsLoading: boolean = true;
    activeBoard: Board | undefined;
    createdById?: string;
    boardId?: string;

    constructor() {
        makeAutoObservable(this);
    }

    //#region Hub Events

    createHubConnection = (boardId: string) => {
        this.isBoardDetailsLoading = true;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.boardUrl + '?boardId=' + boardId, {
                accessTokenFactory: () => store.authStore.token!,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None) //change the log level for debugging
            .build();

        this.hubConnection?.start().catch(e => console.log(e));

        this.attachHubEvents();
        store.cardStore.attachHubEvents();
        store.cardMemberStore.attachHubEvents();
    }

    attachHubEvents = () => {
        if (!this.hubConnection)
            return;

        this.hubConnection.on("LoadBoard", (board: any) => {
            runInAction(() => {
                this.board = board;
                this.createdById = board.workSpace.createdBy;
                this.activeBoard = board;
                this.isBoardDetailsLoading = false;
            });
        });

        this.hubConnection.on("ReceiveCardGroup", (cardGroup: CardGroup) => {
            runInAction(() => {
                if (this.board) {
                    this.board = {
                        ...this.board,
                        cardGroups: [
                            ...this.board.cardGroups,
                            cardGroup
                        ],
                        order: [
                            ...this.board.order,
                            cardGroup.id
                        ]
                    } as Board;
                }
            });
        });

        this.hubConnection.on("ReceiveUpdatedCardGroup", (cardGroup: { id: string, title: string }) => {
            let currentCardGroup = this.board?.cardGroups.find(cg => cg.id === cardGroup.id);

            runInAction(() => {
                if (this.board) {
                    this.board = {
                        ...this.board,
                        cardGroups: [
                            ...this.board.cardGroups.filter(cg => cg.id !== cardGroup.id),
                            {
                                ...currentCardGroup,
                                title: cardGroup.title,
                            }
                        ]
                    } as Board;
                }
            });
        });

        this.hubConnection.on("DeleteCardGroup", (cardGroupId: string) => {
            runInAction(() => {
                if (this.board) {
                    this.board = {
                        ...this.board,
                        cardGroups: [
                            ...this.board.cardGroups.filter(cg => cg.id !== cardGroupId)!,
                        ],
                        order: [
                            ...this.board.order.filter(cg => cg !== cardGroupId),
                        ]
                    } as Board;
                }
            });
        });

        this.hubConnection.on("ReceiveBoardOrderUpdate", (boardOrder: string[]) => {
            runInAction(() => {
                this.board = {
                    ...this.board,
                    order: boardOrder
                } as Board;
            });
        });

        this.hubConnection.on("ReceiveCardGroupOrderUpdate", (cardGroupOrder: CardGroupOrder) => {
            let remainingCardGroups = this.board!.cardGroups.filter(cg => cg.id !== cardGroupOrder.sourceCardGroup.id && cg.id !== cardGroupOrder.destinationCardGroup.id);
            let sourceCardGroup = this.board?.cardGroups.find(cg => cg.id === cardGroupOrder.sourceCardGroup.id);
            let destinationCardGroup = this.board?.cardGroups.find(cg => cg.id === cardGroupOrder.destinationCardGroup.id);

            runInAction(() => {
                this.board = {
                    ...this.board,
                    cardGroups: [
                        ...remainingCardGroups,
                        {
                            ...sourceCardGroup,
                            cards: cardGroupOrder.isSameDestination ? sourceCardGroup?.cards : sourceCardGroup?.cards.filter(c => c.id !== cardGroupOrder.draggedId),
                            order: cardGroupOrder.sourceCardGroup.order
                        },
                        {
                            ...destinationCardGroup,
                            cards: cardGroupOrder.isSameDestination ? sourceCardGroup?.cards : [
                                ...destinationCardGroup!.cards,
                                {
                                    ...sourceCardGroup?.cards.find(c => c.id === cardGroupOrder.draggedId),
                                    cardGroupId: destinationCardGroup?.id
                                }
                            ],
                            order: cardGroupOrder.destinationCardGroup.order
                        }
                    ]
                } as Board;
            });
        });

        this.hubConnection.on("ReceiveBoardCardUpdate", (card: Card) => {
            let currentCardGroup = this.board?.cardGroups.find(cg => cg.id === card.cardGroupId);
            let currentCard = currentCardGroup?.cards.find(c => c.id === card.id);
            runInAction(() => {
                this.board = {
                    ...this.board,
                    cardGroups: [
                        ...this.board?.cardGroups.filter(cg => cg.id !== card.cardGroupId)!,
                        {
                            ...currentCardGroup,
                            cards: currentCardGroup?.cards.map(c => {
                                if (c.id === card.id)
                                    return {
                                        ...currentCard,
                                        title: card.title
                                    }
                                return c;
                            })
                        }
                    ]
                } as Board;
            });
        });
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch((error) => console.log("Error stopping connection: ", error));
    };

    //#endregion

    updateBoardOrder = async (values: UpdateOrderValues) => {
        try {
            this.hubConnection?.invoke("UpdateBoardOrder", values);
        } catch (e) {
            console.log(e);
        }
    }

    filterBoards = async (filters: FilterValues) => {
        this.isBoardListLoading = true;

        try {
            const { total, data } = await agent.Boards.filter(filters);
            runInAction(() => {
                this.boards = data;
                this.boardsTotal = total;
                this.isBoardListLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isBoardListLoading = false;
        }
    }

    getBoardId = async (cardId) => {
        try {
            const boardId = await agent.Boards.getBoardByCardId(cardId);
            runInAction(() => {
                if (boardId) {
                    history.push(`/workspaces/projects/${boardId}`)
                    history.push(`/workspaces/projects/${boardId}?task=${cardId}`);
                    store.notificationModalStore.closeModal();
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    loadBoardDetails = async (id: string) => {
        this.isBoardDetailsLoading = true;
        try {
            const board = await agent.Boards.get(id);
            runInAction(() => {
                this.board = board;
                this.isBoardDetailsLoading = false;
            });
        } catch (e) {
            console.log(e);
            this.isBoardDetailsLoading = false;
        }
    }

    loadBoard = async (id: string) => {
        this.isLoading = true;

        try {
            let board = await agent.Boards.get(id);
            this.isLoading = false;
            return board;
        } catch (e) {
            throw e;
        }
    }

    getAllBoards = async (workSpaceId: string) => {
        this.isLoading = true;
        try {
            let boards = await agent.Boards.getAll(workSpaceId);
            this.isLoading = false;
            return boards;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    add = async (board: BoardFormValues) => {
        try {
            const boardId = await agent.Boards.add(board);
            runInAction(() => {

                if (boardId) {
                    toast.success('Project was created successfully.');
                    history.push(`/workspaces/board/${board.workSpaceId}`);
                    return true;
                }

                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    update = async (board: BoardFormValues) => {
        try {
            const boardId = await agent.Boards.update(board);
            runInAction(() => {
                if (boardId) {
                    toast.success('Project was updated successfully.');
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    deleteBoard = async (id: string, filters: FilterValues) => {
        try {
            await agent.Boards.delete(id);
            this.filterBoards(filters);
            runInAction(() => {
                toast.success('Project was deleted successfully.');
            });
        } catch (e) {
            console.log(e);
        }
    }

}